import React from "react"

import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../components/seo"

import Button from "../components/button"
import MainBanner from "../components/main-banner"
import ConceptSection from "../components/concept-section"

import DinosaurIcon from "../assets/icons/icons-1x/dinosaur.svg"
import IdeaIcon from "../assets/icons/icons-1x/idea.svg"
import PlanetIcon from "../assets/icons/icons-1x/planet.svg"
import MeiquerTVLogo from "../assets/logos/logo-meiquer-tv.svg"

const MeiquerTVPage = props => {
  const data = useStaticQuery(graphql`
    query MeiquerTVQuery {
      watercolorImg: file(relativePath: { eq: "meiquer-bg-05.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const mainBannerContent = (
    <>
      <img style={{ width: "7em" }} src={MeiquerTVLogo} alt="Meiquer TV Logo" />
      <p>
        Canal con videos y actividades de{" "}
        <span className="c-turquoise w-bold">juego creativo</span>{" "}
        <span className="w-bold">gratuitas</span> para toda la familia,{" "}
        diseñadas para hacer en casa y ¡divertirse!.
      </p>
      <div className="columns">
        <div className="column is-two-thirds pl-0">
          <Button>
            <span>Suscribirme</span>
          </Button>
        </div>
      </div>
    </>
  )

  const reasons = [
    {
      top: (
        <img style={{ height: "10rem" }} src={PlanetIcon} alt="Ícono Planeta" />
      ),
      content: (
        <>
          <h3>Actividades familiares</h3>
          <p>
            Los videos que encontrarás en Meiquer TV están dirigidos a toda la
            familia.
          </p>
        </>
      ),
    },
    {
      top: <img style={{ height: "10rem" }} src={IdeaIcon} alt="Ícono Idea" />,
      content: (
        <>
          <h3>Experimentos caseros</h3>
          <p>
            Nuestra casa es un mundo para experimentar, explorar y aprender.
            Nosotros te damos el tour.
          </p>
        </>
      ),
    },
    {
      top: (
        <img
          style={{ height: "10rem" }}
          src={DinosaurIcon}
          alt="Ícono Dinosaurio"
        />
      ),
      content: (
        <>
          <h3>Temas globales</h3>
          <p>
            Buscamos hablar de lo que pasa en el mundo, desde problemas hasta
            las soluciones globales.
          </p>
        </>
      ),
    },
  ]

  const reasonsTitle = (
    <h2 className="w-normal text-right">
      ¿Qué puedo <span className="c-turquoise w-bold">explorar</span> en el{" "}
      <span className="w-bold">canal?</span>
    </h2>
  )

  return (
    <Layout location={props.location}>
      <SEO title="Meiquer TV" />
      <MainBanner
        image={data.watercolorImg.childImageSharp.fluid}
        content={mainBannerContent}
      />
      <ConceptSection concepts={reasons} title={reasonsTitle} />
    </Layout>
  )
}

export default MeiquerTVPage
